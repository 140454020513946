@font-face {
  font-family: 'Gotham-Bold';
  src: url('./assets/fonts/Gotham-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gs1_icons';
  font-style: normal;
  font-weight: normal;

  src: url('./assets/fonts/gs1_icons.eot?hzvadk');
  src: url('./assets/fonts/gs1_icons.eot?#iefixhzvadk') format('embedded-opentype'),
    url('./assets/fonts/gs1_icons.woff?hzvadk') format('woff'),
    url('./assets/fonts/gs1_icons.ttf?hzvadk') format('truetype'),
    url('./assets/fonts/gs1_icons.svg?hzvadk#gs1_icons') format('svg');
}

@font-face {
  font-family: 'Gotham-ssm-medium';
  src: url('./assets/fonts/Gotham-ssm-medium.otf') format('woff');
}
@font-face {
  font-family: 'Gotham-ssm-light';
  src: url('./assets/fonts/Gotham-ssm-light.otf') format('woff');
}
@font-face {
  font-family: 'Gotham-ssm-Bold';
  src: url('./assets/fonts/Gotham-ssm-Bold.otf') format('woff');
}

@font-face {
  font-family: 'GothamSSm-Book-Regular';
  src: url('./assets/fonts/GothamSSm-Book-Regular.otf') format('woff');
}

@font-face {
  font-family: 'Gotham SSm A';

  src: url('./assets/fonts/Gotham-Medium-A.otf');

  font-weight: 400;
  font-style: normal;
  line-height: 1.625;
}
@font-face {
  font-family: 'Gotham SSm A';
  src: url('./assets/fonts/Gotham-Medium.woff') format('woff');
  unicode-range: U+30-39;
}
@font-face {
  font-family: 'Gotham SSm B';
  src: url('./assets/fonts/Gotham-Medium.eot');
  src: url('./assets/fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Gotham-Medium.svg#Gotham') format('svg'),
    url('./assets/fonts/Gotham-Medium.woff') format('woff'), url('./assets/fonts/Gotham-Medium.ttf') format('truetype');
}
[class^='gs1icon-'],
[class*=' gs1icon-'] {
  font-family: 'gs1_icons';
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;

  speak: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.gs1icon-zoom_out:before {
  content: '\e600';
}
.gs1icon-zoom_in:before {
  content: '\e601';
}
.gs1icon-twitter:before {
  content: '\e602';
}
.gs1icon-stop:before {
  content: '\e603';
}
.gs1icon-star:before {
  content: '\e604';
}
.gs1icon-skip_start:before {
  content: '\e605';
}
.gs1icon-skip_next:before {
  content: '\e606';
}
.gs1icon-search:before {
  content: '\e607';
}
.gs1icon-print:before {
  content: '\e608';
}
.gs1icon-play:before {
  content: '\e609';
}
.gs1icon-pause:before {
  content: '\e60a';
}
.gs1icon-open_lock:before {
  content: '\e60b';
}
.gs1icon-menu:before {
  content: '\e60c';
}
.gs1icon-lock:before {
  content: '\e60d';
}
.gs1icon-location:before {
  content: '\e60e';
}
.gs1icon-linkedin:before {
  content: '\e60f';
}
.gs1icon-instagram:before {
  content: '\e610';
}
.gs1icon-home:before {
  content: '\e611';
}
.gs1icon-help:before {
  content: '\e612';
}
.gs1icon-flickr:before {
  content: '\e613';
}
.gs1icon-facebook:before {
  content: '\e614';
}
.gs1icon-download:before {
  content: '\e615';
}
.gs1icon-close:before {
  content: '\e616';
}
.gs1icon-clock:before {
  content: '\e617';
}
.gs1icon-check:before {
  content: '\e618';
}
.gs1icon-calendar:before {
  content: '\e619';
}
.gs1icon-arrow_up:before {
  content: '\e61a';
}
.gs1icon-arrow_right:before {
  content: '\e61b';
  color: #000;
  font-weight: bolder;
}
.gs1icon-arrow_left:before {
  content: '\e61c';
  font-weight: bolder;
}
.gs1icon-arrow_down:before {
  content: '\e61d';
}
body {
  font-family: 'Gotham SSm 5r', 'Gotham SSm A', 'Gotham SSm B';
  font-weight: 500;
  font-style: normal;
}
