.notfound {
  margin-top: 200px;
  div.dialog {
    width: 100%;
    max-width: 33em;
    margin: 4em auto 0;
  }

  div.dialog > div {
    border: 1px solid #ccc;
    border-right-color: #999;
    border-left-color: #999;
    border-bottom-color: #bbb;
    border-top: #b00100 solid 4px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    background-color: white;
    padding: 7px 12% 0;
    box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
    font-family: arial, sans-serif;
    color: #2e2f30;
    text-align: center;
    margin: 0;
  }

  h1 {
    font-size: 100%;
    color: #730e15;
    line-height: 1.5em;
    font-weight: bold;
    font-family: arial, sans-serif;
  }

  div.dialog > p {
    margin: 0 0 1em;
    padding: 1em;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-right-color: #999;
    border-left-color: #999;
    border-bottom-color: #999;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-color: #dadada;
    color: #666;
    box-shadow: 0 3px 8px rgba(50, 50, 50, 0.17);
    font-family: arial, sans-serif;
  }
}
