.tabs-wrapper {
    .tabs-header {
        display: flex;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .tabs-item {
            padding: 10px 25px;
            color: #888B8D;
            cursor: pointer;
        }

        .tabs-item-active {
            font-weight: bold;
            color: #002c6c;
            border-bottom: solid 5px #fbb034;
        }
    }
}