@import '../theme/breakpoints.scss';

a {
  color: #008dbd !important;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  font-family: 'Gotham SSm A', 'Gotham SSm B';
}

body {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
}

.page {
  position: relative;
  background-image: linear-gradient(to top, #f5f5f5 71px, #fff 286px);
  width: 100%;
}

.custom-main-style {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
