@import '../../theme/breakpoints.scss';

.wrapper {
  background-color: #fff;
  .main-header-login {
    color: #002c6c;
    margin-top: 0;
    font-size: 42px;
    text-align: left;
  }

  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    font-size: 18px;
  }

  .buttons {
    .ant-btn {
      background-color: #f26334;
      color: #f4f4f4;
      border-color: #f26334;
      border-radius: 0;
      padding: 25px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
    }
    Button {
      width: 100%;
      align-self: center;
      font-family: 'GothamSSm-Book-Regular';
      font-size: 1.25rem;
    }
  }
  .ant-form-item-control-input-content {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .col-width {
    width: 60vh;
    padding: 20px 47px;
  }

  .font-size-register-forget {
    font-size: 18px;
  }

  .remember-me {
    margin-left: 10px;
  }
  .top-header {
    background-color: #002c6c;
    padding: 20px 47px;
    div {
      color: #f4f4f4;
      font-family: 'GothamSSm-Book-Regular';
      font-size: 20px;
      font-weight: 100;
    }
    div:nth-child(2) {
      font-size: 16px;
      font-family: 'Gotham-ssm-light';
    }
  }
  .forget-password {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    margin-bottom: 20px;
    a {
      text-decoration: underline;
      font-family: 'GothamSSm-Book-Regular';
      color: #008dbd !important;
    }
  }
  .Field {
    padding: 10px !important;
  }
}

@media screen and (max-width: $md) {
  .wrapper {
    .col-width {
      width: 50vh;
      padding: 20px 47px;
    }
  }
}

@media screen and (max-width: $xs) {
  .wrapper {
    .col-width {
      width: 40vh;
    }
    .top-header {
      div {
        font-size: 10px;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: $xxs) {
  .wrapper {
    .col-width {
      width: 100%;
      padding: 20px 47px;
    }
    .top-header {
      div {
        color: #fff;
      }
    }
  }
}
