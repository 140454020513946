.main-layout {
  background-color: #f4f4f4 !important;
  .ant-layout-header {
    padding: 0px;
    height: auto;
  }
  .ant-layout-footer {
    padding: 0px;
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
}
