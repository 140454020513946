@import '../../../theme/breakpoints.scss';

.header-wrapper {
  background-color: #fff;
  border-bottom: 1px solid #d9dada;
  box-shadow: 0 1px 0 #ececec;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  align-self: center;
  row-gap: 0px;
  justify-content: space-between;
  padding-left: 4.5%;
  padding-right: 4.5%;
  .right-nav-style {
    display: flex;
    align-items: center;
  }
  .header-text {
    color: #002c6c;
    font-weight: bold;
    font-size: 1.8rem;
    margin-left: 2vh;
    align-self: center;
    align-items: center;
    font-family: 'GothamSSm-Book-Regular';
  }

  .bars {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .icon-bar {
      background-color: rgb(3, 44, 107);
      height: 3px;
      width: 25px;
      margin-top: 5px;
      border-radius: 1px;
    }
  }

  // @media screen and (max-width: $md) {
  //   .header-wrapper {
  //     margin: 0px 0px 0px;
  //     padding-bottom: 10px;
  //     padding-top: 10px;
  //   }
  //   .navbar-image {
  //     margin-left: 20px;
  //   }
  //   .header-text {
  //     font-size: 1.5rem;
  //   }
  // }
  .logo-style {
    width: 85px;
  }

  @media screen and (max-width: $xs) {
    .logo-style {
      width: 10vh;
      height: 10vh;
    }
    .header-text {
      font-size: 1.5rem;
      margin-left: 0.5vh;
    }
    .bars {
      .icon-bar {
        height: 3px;
        width: 20px;
      }
    }
  }
  @media screen and (max-width: $xxs) {
    .header-text {
      font-size: 1.2rem;
      margin-left: 0vh;
      margin-top: 6px;
    }
    .bars {
      .icon-bar {
        height: 3px;
        width: 20px;
      }
    }
  }
}

.optionsMobile {
  display: flex;
  flex-direction: column;
  padding-left: 45px;
  padding-top: 15px;
}

.navbar-static-top {
  position: sticky;
  margin: 0;
  padding: 0;
  top: 0;
  border: none;
  z-index: 10;
  width: 100%;
}

.selected::after {
  border-bottom: 5px solid #f26334;
  bottom: 4px;
  content: ' ';
  left: 0;
  position: absolute;
  right: 0;
}

.options {
  display: flex;
  align-items: center;
}

.marginOptionsMobile {
  margin-top: 5px;
}
.marginOptionsMobileSelected {
  margin-top: 10px;
  // margin-bottom: 5px;
}

.option {
  padding: 5px 0px;
  margin: 0 10px;

  font-size: 16px;
  color: #454545 !important;
}

.option:hover {
  color: gray !important;
}

.option-selected {
  text-decoration: underline;
  text-decoration-color: #f26334;
  text-decoration-style: solid;
  text-underline-offset: 10px;
  text-decoration-thickness: 5px;
}

.option-selected:hover {
  text-decoration: underline;
  text-decoration-color: #f26334;
  text-decoration-style: solid;
  text-underline-offset: 10px;
  text-decoration-thickness: 5px;
}

@keyframes openingMenu {
  from {
    width: 100vw;
    height: 0vh;
    transform: scaleY(0);
  }
  to {
    width: 100vw;
    height: 20vh;
    transform: scaleY(1);
  }
}

.menu-open {
  animation: openingMenu 0.5s ease-in;
  width: 100vw;
  transform: scaleY(1);
}

@keyframes closingMenu {
  from {
    width: 100vw;
    height: 20vh;
    transform: scaleY(1);
  }
  to {
    width: 100vw;
    height: 0vh;
    transform: scaleY(0);
  }
}

.menu-close {
  animation: closingMenu 0.5s ease-in;
  width: 100vw;
  height: 0vh;
  transform: scaleY(0);
}

.optionsMobileDisplay {
  display: contents;
}

.active-link-style {
  text-decoration: none;
  border-bottom: 3px solid #f26334;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  color: #f26334 !important;
}
