.single-breadcrumb-style {
  font-size: 15px;
  font-family: 'GothamSSm-Book-Regular';
}

.breadcrumb-wrapper {
  .ant-breadcrumb {
    margin-bottom: 10px;
  }
}
