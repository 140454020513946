table.table.table-striped {
  width: 100%;
  thead tr {
    height: 60px;
    background-color: white;
    color: white;

    th {
      padding: 20px 12px;
      font-weight: 100;
      font-size: 16px;
      color: white !important;
      background-color: #002c6c;
      text-align: left;
      font-family: 'Gotham-ssm-Bold';
    }
    :nth-child(1) {
      border: 1px solid #d8d8d8;
      border-right: none;
    }

    :nth-child(2) {
      border: 1px solid #d8d8d8;
    }
    :nth-child(3) {
      border: 1px solid #d8d8d8;
    }
    :nth-child(4) {
      border: 1px solid #d8d8d8;
    }
    :nth-child(5) {
      border: 1px solid #d8d8d8;
    }
    :nth-child(6) {
      border: 1px solid #d8d8d8;
    }
  }
  tbody tr {
    td.bulk-search-table:nth-child(2) {
      a {
        font-size: 16px;
        line-height: 16px;
        padding: 40px;
        color: #002c6c;
      }
    }
    td.bulk-search-table:nth-child(3) {
      a {
        color: #454545;
      }
    }
  }
  &.search-result-main-table {
    thead {
      tr {
        th {
          // border: 1px solid #d8d8d8;
          vertical-align: top;
        }
        th:nth-child(1) {
          border-top-left-radius: 7px;
          border-right: 1px solid #d8d8d8;
        }
        th:nth-child(2) {
          border-right: 1px solid #d8d8d8;
        }
        th:nth-child(3) {
          border-right: 1px solid #d8d8d8;
        }
        th:nth-child(4) {
          border-right: 1px solid #d8d8d8;
        }
        th:nth-child(5) {
          border-right: 1px solid #d8d8d8;
        }
        th:nth-child(6) {
          border-top-right-radius: 7px;
        }
      }
    }
    tbody {
      background-color: white;
      tr {
        td {
          border: 1px solid #d8d8d8;
          font-size: 16px;
          line-height: 1px;
          color: #002c6c;
          min-width: 100px;
          text-align: center;
        }
      }
    }
  }
}

.pagination {
  margin-top: 30px;
  margin-bottom: 60px;
  .active {
    background-color: #008dbd;
    color: white !important;
    padding: 9px 12px !important;
    border: 1px solid #008dbd !important;
    border-radius: 3px;
  }
  .notActive {
    background-color: #f5f5f5;
    color: #008dbd !important;
    padding: 9px 12px !important;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
  }
}

.card-styling {
  .title {
    font-size: 16px;
  }
  .content {
    color: #002c6c;
    margin-left: 5px;
    font-size: 16px;
  }
  .row-alignment {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .ant-card {
    background-image: linear-gradient(to bottom, #fff 0px, #f5f5f5 100px);
    border: solid 1px #b1b3b3;
    margin-bottom: 10px;
  }
  .no-margin {
    margin-left: 0;
  }
}
.error {
  .ant-card-body {
    // background-color: #ffdfdf;
    // border-left: 4px solid #ba1b23;
  }
}
.cards-list-margin {
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
}

.list-margin-bottom {
  margin-top: 20px;
}

.results-table-margin {
  // width: 100%;
  // margin: auto;
}
.result-table-row-table {
  background-color: rgba($color: #f26334, $alpha: 0.1);
  border-left: 10px solid #f26334;
}
