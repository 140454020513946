.top-message {
  margin: 40px 0;
  color: #454545;

  .complete {
    background-color: #ccf1dc;
    padding: 20px;
    display: flex;
    flex-direction: row;
    border-radius: 2px;
    border-left: 4px solid #09f06d;

    .logo {
      margin-right: 20px;
    }
    img {
      max-width: 80px;
    }
    .massage-title {
      color: #026b30;
      font-size: 1.25rem;
      font-family: 'Gotham-ssm-Bold';
    }
    .massage-description {
      color: #262626;
      font-size: 0.9rem;
      text-transform: none;
      font-family: 'GothamSSm-Book-Regular';
    }
    .message-description-copmany {
      color: #026b30;
      font-size: 1rem;
      font-family: 'Gotham-ssm-Bold';
    }
  }
}
