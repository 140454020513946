.bulk-wrapper {
  padding: 20px;
  background-color: white;

  table.table.table-striped {
    width: 100%;
    margin-top: 60px;

    thead tr {
      height: 60px;
      background-color: white;

      th {
        padding: 20px 12px;

        font-weight: 100;
        font-size: 16px;
        color: #454545;
        text-align: left;
      }

      :nth-child(1) {
        border: 1px solid #d8d8d8;
        border-right: none;
      }

      :nth-child(2) {
        border: 1px solid #d8d8d8;
      }
    }

    tbody tr {
      td.bulk-search-table:nth-child(2) {
        a {
          font-size: 16px;
          line-height: 16px;
          padding: 0px;
          color: #002c6c;
        }
      }

      td.bulk-search-table:nth-child(3) {
        a {
          color: #454545;
        }
      }
    }

    &.bulk-search-main-table {
      thead {
        tr {
          th {
            border: 1px solid #d8d8d8;
            vertical-align: top;
          }
        }
      }

      tbody {
        tr {
          td {
            border: 1px solid #d8d8d8;
            font-size: 16px;
            line-height: 16px;
            padding: 0px 0px 0px 5px;
            color: #002c6c;
            line-break: normal;
          }
        }
      }
    }
  }

  .bulk-search-subtitle {
    color: #454545;
    font-size: 30px;
  }

  .export-results-link {
    margin-left: 2px;
  }

  .record-error {
    background-color: #ffdfdf;
    border-left: 4px solid #ba1b23;
  }

  .image-style {
    object-fit: contain;
    margin: 5px;
  }

  .image-bulk-container {
    padding: 1em;
    align-items: center;
    width: 150px;

    .text-position {
      justify-content: center;
      display: flex;
    }

    img {
      width: 100%;
      object-fit: contain;
      height: 200px;
      align-self: center;
    }
  }
}

.search_filter_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .ant-input-search {
    border-left: 2.5px solid rgb(204, 201, 201);
    padding-left: 3%;
  }

  @media (max-width:766px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-input-search {
      width: 100%;
      border: none;
      padding-top: 4%;
    }
  }
}