.search-results-styling {
  width: 90%;
  margin: auto;
  padding-top: 30px;
  padding: 20px;
}
.single-result-breadCrump {
  font-weight: bold;
  font-size: large;
}
.main-header-search-results {
  color: #002c6c;
  padding: 54px 0;
  padding-top: 80px;
  margin-top: 0;
  font-size: 42px;
  font-weight: bold;
}

.search-results {
  color: #454545;
  font-size: 30px;
  line-height: 30px;
  margin: 2rem 0;
  font-family: 'Gotham SSm 5r', 'Gotham SSm A', 'Gotham SSm B';
}

.search-results-title {
  color: #002c6c;
  font-family: 'Gotham-ssm-Bold';
  font-size: 34px;
  margin-bottom: 20px;
}
