.top-message {
  margin: 40px 0;
  color: #454545;
  text-transform: capitalize;
  border-radius: 20px;

  .error {
    background-color: #ffdfdf;
    padding: 20px;
    border-left: 4px solid #ba1b23;
    color: #ba1b23;
    border-radius: 2px;

    img {
      max-width: 30px;
    }
    .text {
      font-weight: light;
      display: inline-block;
      vertical-align: top;
      padding-left: 15px;
      .message {
        font-size: 20px;
      }
      .description {
        font-size: 16px;
      }
    }
  }
  .warning {
    background-color: #ffe89b;
    padding: 20px;
    font-size: 18px;
    border-left: 4px solid #fbb034;
    border-radius: 2px;
  }
  .message-title {
    // font-size: large;
    font-size: 1rem;
  }
  .message-description {
    color: #262626;
    font-size: 0.9rem;
    text-transform: none;
    a {
      font-weight: bold !important;
      color: #ba1b23;
    }
  }
}
