@import '../../theme/breakpoints.scss';

.main-header-bulk-search {
  color: #002c6c;
  padding: 54px 0;
  padding-top: 80px;
  margin-top: 0;
  font-size: 42px;
  font-weight: bold;
}

.bulk-search {
  color: #002c6c;
  font-size: 30px;
  padding: 0px 0 10px 0;
  font-family: 'Gotham-ssm-Bold';
}

.top-description {
  font-size: 16px;
  color: #454545;
  padding: 0 0 20px 0;
  font-family: 'Gotham-ssm-light';
}

.barcode-numbers {
  color: #454545;
  font-size: 23px;
  margin-top: 2rem;
}

.drag-and-drop {
  border: 1px solid #d8d8d8;
  // border-top: 0;
  border-radius: 0;
  padding: 30px;
  margin-top: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  .drag-drop {
    color: #888b8d;
    font-size: 18px;
    margin-bottom: 20px;
    font-family: 'GothamSSm-Book-Regular';
  }
  .allowed-text {
    font-size: 16px;
    color: #454545;
    margin-bottom: 20px;
    font-family: 'Gotham-ssm-Bold';
  }
}
.buttons-row {
  margin-top: 20px;

  .search-button-style {
    margin-right: 10px;
    .ant-btn {
      background-color: #008dbd;
      color: #f4f4f4;
      border-color: #008dbd;
      border-radius: 5px;
      padding: 19px;
      margin-right: 50px;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 150px;

      span {
        font-size: 14px;
        font-family: 'Gotham-ssm-Bold';
      }
    }
  }
  .clear-button-style {
    .ant-btn {
      color: #008dbd;
      border-color: #008dbd;
      border-radius: 5px;
      padding: 19px;
      background-color: transparent;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 150px;
      span {
        font-size: 14px;
      }
    }
  }
}
@media screen and( max-width : $md ) {
  .buttons-row {
    align-items: center;
    display: flex;
    justify-content: center;
    .search-button-style {
      margin-right: 10px;
      .ant-btn {
        margin-right: 0px;
      }
    }
  }
}
@media screen and( max-width : $xs ) {
  .buttons-row {
    align-items: center;
    display: flex;
    justify-content: center;
    .search-button-style {
      margin-right: 0px;
    }
  }
}
@media screen and( max-width : $xs ) {
  .buttons-row {
  }
  .search-button-style {
    margin-right: 0;
  }
}
.formItemBulkSearch {
  .ant-input:focus {
    border-color: rgb(242, 99, 52);
    outline: 0;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}

.bulk-search-styling {
  width: 100%;
  margin: auto;
  // align-self: flex-start;
  padding: 20px;
  padding: 35px 4.5%;
}

.screen-width {
  max-width: 80%;
}
@media screen and (max-width: $md) {
  .screen-width {
    max-width: 100%;
  }
}
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  border-radius: 10px;
  padding: 30px;
  color: #b1b3b3;
  font-family: 'Gotham-ssm-light';
}

.download-button {
  width: 240px;
  height: 47px;
  padding: 14px 25px;
  border-radius: 5px;
  border: solid 1px #f0643d;
  color: #f0643d !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: $md) {
  .download-button {
    width: 195px;
  }
}
.custom-file-input {
  color: transparent;
  width: 190px !important;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Choose file';
  color: #888b8d;
  font-family: 'Gotham-ssm-light';
  display: inline-block;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 7px;
  padding: 10px 55px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.file-input-text {
  color: #888b8d;
  font-family: 'GothamSSm-Book-Regular';
}
.right-side {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
