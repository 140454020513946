@import '../../../theme/breakpoints.scss';

.footer-wrapper {
  margin: 0;
  padding: 25px 0px;
  border: none;
  min-height: px;
  background-color: #f4f4f4;
  justify-content: space-between;
  display: flex;
  padding-left: 4.5%;
  padding-right: 4.5%;
  align-items: center;

  .footer-spacing {
    display: flex;
    // flex-direction: row;
  }

  .icons-spacing {
    display: flex;
    flex-direction: row;
  }

  .copy-rights {
    color: #f26334;
    font-size: 14px;
  }

  .copy-rights-bold {
    color: #888b8d;
    font-size: 14px;
    font-weight: bold;
  }

  .icon-links {
    width: 45px;
    height: 45px;
    margin: 0 8px;
  }
}

.footer-wrapper-top {
  height: 2px;
  background-color: #d8d8d8;
  margin-left: 4.5%;
  margin-right: 4.5%;
}
.line {
  background-color: #d8d8d8;
  width: 2px;
  height: 100%;
  margin: 0 1rem;
}

@media screen and (max-width: $lg) {
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .icons-spacing {
    margin-top: 12px;
  }
}

@media screen and (max-width: $md) {
  .footer-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .footer-spacing {
    flex-direction: column;
  }
  .line {
    width: 0;
    margin: 0;
  }
}

.row {
  justify-content: center;
}
