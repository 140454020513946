@import '../../theme/breakpoints.scss';

.background-single-search {
  background-image: url('../../assets/images/checker-top.jpg');
  height: 25vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  text-align: center;
  color: #f4f4f4;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .description {
    position: relative;
    font-size: 22px;
    font-family: 'Gotham-ssm-medium';
  }
  .check-svg-large {
    width: 120px;
    height: 120px;
    position: relative;
    right: 0;
    margin: 0 6vh;
  }
  .check-svg-transparent {
    display: none;
  }
}

.background-single-search::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.search-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media screen and (max-width: $lg) {
  .search-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: $lg) {
  .check-svg-large {
    display: none;
  }
}

.main-title {
  z-index: 2;
  position: relative;
  padding-top: 100px;
  margin: 0;
  color: white;
  font-size: 30px;
}

.card-title {
  color: #002c6c;
  font-size: 25px;
  margin-bottom: 20px;
  font-family: 'Gotham-ssm-medium';
}

.single-search {
  max-width: 70%;
  margin: 0 auto;
  background-color: white;
  bottom: 40px;
  position: relative;
  z-index: 5;
  min-height: 250px;
  border-top: 4px solid #00b6de;
  padding: 30px;
}

@media screen and (max-width: $lg) {
  .single-search {
    bottom: 50px;
  }
}

.search-button {
  padding: 10px 20px;
  border: none;
  color: #f4f4f4;
  line-height: 20px;
  font-size: 16px;
  background-color: #cd3c0d;
  width: 100%;
  cursor: pointer;
  font-family: 'GothamSSm-Book-Regular';
}

.search-button:hover {
  background-color: #a82c05;
  text-decoration: none;
  border-color: #a82c05;
}

.check-svg {
  max-width: 30px;
  padding-right: 10px;
  width: 30px;
  height: 30px;
}

.checks-alignment {
  color: #454545;
  font-family: 'GothamSSm-Book-Regular';
  line-break: auto;
  word-wrap: wrap;
  line-break: auto;
}

.checks-alignment {
  font-family: 'GothamSSm-Book-Regular';
  flex-wrap: nowrap;
  text-align: left;
  line-break: unset;
  width: 50vh;
}

.margin-checks {
  margin-top: 0px;
}

@media screen and (max-width: $lg) {
  .checks-alignment {
    width: 70vw;
    margin-left: 7vw;
  }
  .margin-checks {
    margin-top: 20px;
  }
}

.example-search {
  clear: both;
  color: #454545;
  font-family: 'GothamSSm-Book-Regular';
  a {
    font-family: 'GothamSSm-Book-Regular';
    color: #002c6c;
  }
  a:hover {
    text-decoration: underline;
  }
}
.formItem {
  .ant-input {
    font-family: 'GothamSSm-Book-Regular';
  }
  .ant-input-group-addon {
    padding: 0px 0px;
  }
  .ant-input-group {
    display: table;
    width: 450px;
  }
  .textarea.ant-input:focus {
    border-color: #cd3c0d;
  }
  .ant-input-affix-wrapper {
    border: 1px solid #c4d7ed;
    border-right-width: 0px;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    padding: 10px;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: #cd3c0d;
    border-color: #cd3c0d;
  }
  .ant-input-group-addon:last-child:hover {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: #a82c05;
    border-color: #a82c05;
  }
  @media screen and (max-width: $lg) {
    .ant-input-group {
      width: 60vw;
    }
  }
}
