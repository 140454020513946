.search-responses-styling {
  width: 70%;
  margin: auto;
}
.main-header-search-responses {
  color: #002c6c;
  padding: 54px 0;
  padding-top: 80px;
  margin-top: 0;
  font-size: 42px;
  font-weight: bold;
}
