.spin-wrapper {
  width: 100%;
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    font-size: 100px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
