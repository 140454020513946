@import '../../theme/breakpoints.scss';

.row-filters {
  border: 1px solid #b1b3b3;
  margin-bottom: 20px;
  border-radius: 7px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 20px 0px;
  color: #888b8d;
  font-family: 'Gotham-ssm-Bold';
}
@media screen and (max-width: $xl) {
  .row-filters {
    justify-content: center;
  }
}
.col-padding-filters {
  margin-left: 20px;
}

.filter-button-style {
  margin-right: 10px;
  .ant-btn {
    background-color: #008dbd;
    color: #f4f4f4;
    border-color: #008dbd;
    border-radius: 5px;
    padding: 0px 40px;
    margin-right: 50px;

    span {
      font-size: 14px;
      font-family: 'Gotham-ssm-Bold';
    }
  }
}

.reset-button-style {
  margin-right: 20px;
  .ant-btn {
    color: #008dbd;
    border-color: #008dbd;
    border-radius: 5px;
    padding: 0px 40px;
    span {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: $xl) {
  .col-padding-filters {
    margin-left: 0;
  }
}
@media screen and (max-width: $xs) {
  .filter-button-style {
    margin-right: 8px;
    .ant-btn {
      margin-right: 0px;
    }
  }

  .reset-button-style {
    margin-right: 0px;
  }
}
@media screen and (max-width: $xxs) {
  .filter-button-style {
    margin-right: 0px;
  }
}

.select-styling {
  width: 180px;
  width: 90%;
  padding: 6px 12px;
  font-family: 'Gotham-ssm-light';
}
@media screen and (max-width: 991px) {
  .select-styling {
    width: 100%;
  }
}

.left-filters-styling {
  width: 70%;
  margin-bottom: 18px;
}

@media screen and (max-width: $lg) {
  .left-filters-styling {
    width: 100%;
  }
}

.meduim-input {
  width: 100%;
}

.input {
  min-width: 200px;
  padding: 2px 0;
  font-family: 'Gotham-ssm-light';
}
